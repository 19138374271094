import React, { useEffect } from "react"
import { connect } from "react-redux"
import Close from "../images/bclose.png"

const WhiteModal = props => {

    const showHide = props.display ? 'block' : 'none'

    useEffect(()=>{

        if (props.display) {

            document.body.style.overflow = `hidden`
    
        } else {
    
            document.body.style.overflow = `auto`
    
        }

    }, [props.display])

    const modalStyle = {
        color: `black`,
        background: `white`,
        display: showHide,
        position: `fixed`,
        zIndex: 5,
        top: 0,
        left: 0,
        width: `100%`,
        // height: props.height, need to fix mob header thing
        height: `100%`, //temporary
        textAlign: `center`,
        overflow: `auto`
    }

    let picStyle 

    let divStyle

    let embedStyle

    const closeStyle = {
        float: `right`,
        paddingTop: 15,
        paddingRight: 25
    }

    if (props.width <= 1024 && props.width > 500) {

        divStyle = { 
            paddingTop: `75px`
        }

        embedStyle = {
            width: `300px`,
            height: `220px`,
            paddingBottom: `50px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `300px`,
            position: `static`,
        }

    } else if (props.width <= 500) {

        divStyle = {
            paddingTop: `50px`,
            position: `static`,
        }

        embedStyle = {
            width: `250px`,
            height: `183px`,
            paddingBottom: `50px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `250px`,
            position: `static`,
        }

    } else {

        divStyle = {
            paddingTop: `100px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `800px`,
            position: `static`,
        }

        embedStyle = {
            width: `800px`,
            height: `586px`,
            paddingBottom: `50px`,
            position: `static`,
        }

    }

    // const modalPic = <img className={props.classChosen} style={picStyle} src={props.pic.link} alt={props.pic.link}></img>
    const modalPic = <img style={picStyle} src={props.pic.link} alt={props.pic.link}></img>
    // const modalEmbed = <iframe style={picStyle} src={props.pic.link} width="500" height="365" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    const modalEmbed = <iframe style={embedStyle} src={props.pic.link} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    return [
        
        <div style={modalStyle}>
            <div onClick={props.off} className="pointer" style={closeStyle}><img width="10px" src={Close}></img></div>
            <div style={divStyle}>{props.pic.type === "embed" ? modalEmbed : modalPic}</div>
        </div>
    ]

}

const mapStateToProps = (state) => {
    return {
        width: state.width,
        height: state.height
    }
  }
  
export default connect(mapStateToProps)(WhiteModal)