import React, { useRef, useEffect, useState, useLayoutEffect } from "react"
import { connect } from "react-redux"
import Close from "../images/bclose.png"
import White from "../images/lazy.jpg"

const Mundo1 = props => {

    const [firstRect, styleTitle] = useState({display: `none`})
    const [embedRect, styleGraf] = useState({display: `none`})

    useEffect(()=>{

        if (props.display) {

            document.body.style.overflow = `hidden`
            styleTitle({marginLeft: des1.current.getBoundingClientRect().left, textAlign: `left`})
            styleGraf({marginLeft: embedRef.current.getBoundingClientRect().left, textAlign: `left`})

        } else {
    
            document.body.style.overflow = `auto`
    
        }

    }, [props.display])

    const des1 = useRef("des1")
    const des2 = useRef("des2")
    const des3 = useRef("des3")
    const des4 = useRef("des4")
    const des5 = useRef("des5")
    const des6 = useRef("des6")
    const des7 = useRef("des7")
    const des8 = useRef("des8")
    const des9 = useRef("des9")
    const des10 = useRef("des10")
    const des11 = useRef("des11")
    const des12 = useRef("des12")
    const des13 = useRef("des13")
    const des14 = useRef("des14")
    const embedRef = useRef("embed")

    const showHide = props.display ? 'block' : 'none'

    const eng = 
    <div>
        <p>Desencuentros is an illustrated documentary that comes from the desire to deepen in the concept of misunderstanding from its most wider and universal comprehension, in order to promote the encounter amongst twenty different real stories from many people and twenty illustrators from different countries of Latin America.</p>
        <p>The book gathers twenty stories and the images that describe those images. The book’s narrative pretends also a misunderstanding reading in which the illustrations are related to other texts different form the originals.</p>
        <p>
            18 x 21 cm<br />
            Risograph impression, soft cover<br />
            98 pages<br />
            Elefante<br />
            Mexico City<br />
            Concept & Direction: Juan Pablo López-Fonseca<br />
            Illustrations on the post by: Daniela Villanueva (México), Catalina Bu (Chile), Maru Calva (México), Marina Silva (México), Marina Silva (México), Rorraz (México), Santiago Marín (México), Armando Fonseca (México), Manuel Bueno (México), María Luque (Argentina)<br />
            Photographs & Video by: Bruno Bancalari<br />
            Design: Proyectos niguém: María Marín de Buen / Maru Calva<br />
            2017
        </p>
    </div>

    const esp = 
    <div>
        <p>Desencuentros es una publicación documental-ilustrada que parte del deseo de profundizar sobre el concepto del desencuentro desde su más amplia y universal comprensión, para así generar el encuentro entre veinte historias reales de distintas personas y veinte ilustradores latinoamericanos diferentes.</p>
        <p>El libro reúne los textos de veinte historias y las imágenes que los describen. La narrativa del libro propone una lectura desencontrada en la que las imágenes se relacionan con otros textos distintos a los originales.</p>
        <p>
            18 x 21 cm<br />
            Impresión risográfica, pasta blanda<br />
            98 páginas<br />
            Elefante<br />
            Ciudad de México<br />
            Concepto y Dirección: Juan Pablo López-Fonseca<br />
            Ilustraciones en el post: Daniela Villanueva (México), Catalina Bu (Chile), Maru Calva (México), Marina Silva (México), Marina Silva (México), Rorraz (México), Santiago Marín (México), Armando Fonseca (México), Manuel Bueno (México), María Luque (Argentina)<br />
            Fotografías y Video por: Bruno Bancalari<br />
            Diseño: Proyectos ninguém: María Marín de Buen / Maru Calva<br />
            2017
        </p>
    </div>

    useLayoutEffect(()=>{

        const lazies = [des1,des2,des3,des4,des5,des6,des7,des8,des9,des10,des11,des12,des13,des14]

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: [0.10, 0.20, 0.30, 0.40, 0.50, 0.60, 0.70, 0.80, 0.90, 1.00]
        }

        const lazyImageObserver = new IntersectionObserver(analies, options);

        function analies (entries, observer) {

            entries.map( entry => {

                if (entry.isIntersecting) {
    
                    entry.target.src = entry.target.dataset.src;
                            
                }

            })
    
        }

        lazies.map(lazy=>{

            lazyImageObserver.observe(lazy.current)

        })

    }, [])

    const modalStyle = {
        top: 0,
        left: 0,
        color: `black`,
        background: `white`,
        display: showHide,
        position: `fixed`,
        zIndex: 5,
        width: props.width,
        // height: props.height, need to fix mob header thing
        height: `100%`, //temporary
        overflow: `auto`,
        fontSize: `11px`,
        textAlign: `center`
    }

    let divStyle

    let picStyle

    let embedStyle

    let grafStyle

    if (props.width <= 1024 && props.width > 500) {

        divStyle = { 
            paddingTop: `75px`
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `300px`,
            position: `static`,
        }

        embedStyle = {
            width: `300px`,
            height: `220px`,
            paddingBottom: `50px`,
            position: `static`,
        }

        grafStyle = {
            width: `320px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    } else if (props.width <= 500) {

        divStyle = {
            paddingTop: `50px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `250px`,
            position: `static`,
        }

        embedStyle = {
            width: `250px`,
            height: `183px`,
            paddingBottom: `50px`,
            position: `static`,
        }

        grafStyle = {
            width: `190px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    } else {

        divStyle = {
            paddingTop: `100px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `500px`,
            position: `static`,
        }

        embedStyle = {
            width: `500px`,
            height: `366px`,
            paddingBottom: `50px`,
            position: `static`,
        }

        grafStyle = {
            width: `380px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    }

    const closeStyle = {
        float: `right`,
        paddingTop: 15,
        paddingRight: 30,
    }

    return [
        <div style={modalStyle}>
            <div onClick={props.off} className="pointer" style={closeStyle}><img width="10px" src={Close}></img></div>
            <div style={divStyle}>
                <p style={firstRect}>Desencuentros</p>
                <img ref={des1} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/mundo1/1.Elefante_Web_Desencuentros.jpg`} alt="1"></img>
                <br />
                <img ref={des2} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/mundo1/2.Elefante_Web_Desencuentros.jpg`} alt="2"></img>
                <br />
                <img ref={des3} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/mundo1/3.Elefante_Web_Desencuentros.jpg`} alt="3"></img>
                <br />
                <img ref={des4} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/mundo1/4.Elefante_Web_Desencuentros.jpg`} alt="4"></img>
                <br />
                <img ref={des5} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/mundo1/5.Elefante_Web_Desencuentros.jpg`} alt="5"></img>
                <br />
                <img ref={des6} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/mundo1/6.Elefante_Web_Desencuentros.jpg`} alt="6"></img>
                <br />
                <img ref={des7} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/mundo1/7.Elefante_Web_Desencuentros.jpg`} alt="7"></img>
                <br />
                <img ref={des8} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/mundo1/8.Elefante_Web_Desencuentros.jpg`} alt="8"></img>
                <br />
                <img ref={des9} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/mundo1/9.Elefante_Web_Desencuentros.jpg`} alt="9"></img>
                <br />
                <img ref={des10} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/mundo1/10.Elefante_Web_Desencuentros.jpg`} alt="10"></img>
                <br />
                <img ref={des11} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/mundo1/11.Elefante_Web_Desencuentros.jpg`} alt="11"></img>
                <br />
                <img ref={des12} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/mundo1/12.Elefante_Web_Desencuentros.jpg`} alt="12"></img>
                <br />
                <img ref={des13} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/mundo1/13.Elefante_Web_Desencuentros.jpg`} alt="13"></img>
                <br />
                <img ref={des14} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/mundo1/14.Elefante_Web_Desencuentros.jpg`} alt="14"></img>
                <br />
                <iframe style={embedStyle} src="https://player.vimeo.com/video/244768345"  frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                <br />
                <iframe style={embedStyle} ref={embedRef} src="https://player.vimeo.com/video/244740441"  frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                <div style={grafStyle}>{props.lang === "ES" ? esp : eng}</div>
            </div>
        </div>
    ]

}

const mapStateToProps = (state) => {
    return {
        width: state.width,
        height: state.height,
        lang: state.lang
    }
  }
  
export default connect(mapStateToProps)(Mundo1)