import React, { useState, useLayoutEffect, useRef, useEffect } from "react"
import HeaderDecider from "../components/headerDecider"
import { connect } from "react-redux"
import White from "../images/lazy.jpg"
import json from "../test.json"
import Mundo1 from "../components/mundo1"
import mJson from "../mob.json"
import "../styles/journal.css"
import WhiteModal from "../components/WhiteModal"

const Editorial = props => {

    //note: use uselayouteffect VERY carefully; it was holding off the painting of all elements hence NO WIDTH from HeaderDecider
    //being provided

    const [modal, display] = useState(false)
    const [mundo1, displayM1] = useState(false)
    const [pic, choose] = useState({})
    const [classChosen, style] = useState()

    const modalOn = event => {

        event.preventDefault()

        const pic = event.target.dataset.src
        const embed = event.target.dataset.embed
        const picClass = event.target.getAttribute("class")

        //first if checks if modal is open or not
        if ({modal}.modal == false) {

            //this one checks if event has an embed and if so, dispatches info accordingly to white modal
            if (embed) {

                choose({type: `embed`, link: embed})
                display(true)

            } else {

                //first if is for toilets
                if(
                    event.target.dataset.src === "https://d975adv1u4h02.cloudfront.net/16_Elefante_Bitacora.jpg" ||
                    event.target.dataset.src === "https://d975adv1u4h02.cloudfront.net/17_Elefante_Bitacora.jpg" ||
                    event.target.dataset.src === "https://d975adv1u4h02.cloudfront.net/18_Elefante_Bitacora.jpg" ||
                    event.target.dataset.src === "https://d975adv1u4h02.cloudfront.net/19_Elefante_Bitacora.jpg" 
                ) {
                    choose({type: `pic`, link: "https://d975adv1u4h02.cloudfront.net/test/mundo2/Elefante_Web_escusado_color.jpg"})
                    style(picClass)
                    display(true)
                } else {
                    choose({type: `pic`, link: pic})
                    style(picClass)
                    display(true)
                }

            }

        } else {

          display(false)

        }
    
    }

    const modalOff = event => {
        event.preventDefault()

        display(false)
        style()
        choose({})
    }

    const mundo1On = event => {

        event.preventDefault()

        if ({modal}.modal == false) {

            displayM1(true)

        } else {

            displayM1(false)

        }
    }

    const mundo1Off = event => {
        event.preventDefault()

        displayM1(false)
        
    }

    const lazy1 = useRef("lazy1")

    let lazies = [lazy1]

    useEffect(()=>{

        document.body.style.background = `white`

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: [0.10, 0.20, 0.30, 0.40, 0.50, 0.60, 0.70, 0.80, 0.90, 1.00]
        }

        const lazyImageObserver = new IntersectionObserver(analies, options);

        function analies (entries, observer) {

            entries.map( entry => {

                if (entry.isIntersecting) {
    
                    entry.target.src = entry.target.dataset.src;
                            
                }

            })
    
        }

        lazies.map(lazy=>{

            lazyImageObserver.observe(lazy.current)
    
        })
        
    }, [props.width])

    let mother = json

    return [

        <HeaderDecider />,

        <div className="grid-1">
            <img onClick={mundo1On} ref={lazy1} className="item-13 pointer" src={White} data-src={mother.pic13} alt={mother.pic13}></img>
        </div>,
        <Mundo1 off={mundo1Off} display={mundo1} />,

    ]
        
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang,
        width: state.width,
        height: state.height,
    }
  }
  
  export default connect(mapStateToProps)(Editorial)